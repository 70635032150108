$( document ).ready(function() {
	var oldfocus;
	$('#trigger-overlay').on('click',function() {
		$('.overlay-door').css({
			"z-index": 10000
		});
		$('.overlay-door').animate({
			opacity: 1
		}, 300 );
	});
	$('.overlay-door .overlay-close').on('click',function() {
		$('.overlay-door').animate({
			opacity: 0
		}, {
			duration: 300,
			complete: function() {
				$('.overlay-door').css({"z-index": -1});
			}
		});
	});
});