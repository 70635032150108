require("scroll");
require("navigation-mobile");

new WOW().init();


$('.flexslider').flexslider({
	animation: "slide",
	controlNav: false
});


