
$(window).load(function () {

//************* home parralax *****************//
if (window.innerWidth > 992) {
	/*$.fn.homeParallax = function() {
		var scrolledY = $(window).scrollTop();
		var $self = $(this);
		var height = $self.parent().height();
		$self.css({
			'top': ((scrolledY*-0.10))+'px'
		});
	};

	$( window ).scroll(function() {	
		$( ".toulouse-container" ).homeParallax();
	});*/

	$.fn.homeParallax2 = function() {
		var scrolledY = $(window).scrollTop();
		var $self = $(this);
		var height = $self.parent().height();
		$self.css({
			'top': ((scrolledY*0.10))+'px'
		});
	};

	$( window ).scroll(function() {	
		$( ".toulouse-block" ).homeParallax2();
	});
}
})