$(window).load(function() {
  // The slider being synced must be initialized first
  $('#carousel').flexslider({
    animation: "slide",
    controlNav: false,
    animationLoop: false,
    slideshow: false,
    itemWidth: 118,
    direction: "vertical",
    asNavFor: '#slider'
  });
 
  $('#slider').flexslider({
    animation: "slide",
    controlNav: true,
    animationLoop: false,
    directionNav: false,
    slideshow: false,
    sync: "#carousel"
  });
});