require("carousel");
require("navigation-mobile");

new WOW().init();

function hauteurEgale(group) {
	tallest = 0;
	group.css('height', '');
	group.each(function() {
		thisHeight = $(this).height();
		if(thisHeight > tallest) {
			tallest = thisHeight;		
		}
	});
	group.css('height',tallest+'px');
}

$(window).load(function() {
	if (window.innerWidth > 480) {
		hauteurEgale($(".same-height-programmes"));
		hauteurEgale($(".same-height-push-programme"));
	};
	if (window.innerWidth > 768) {		
		hauteurEgale($(".same-height-ctas"));		
	};
	if (window.innerWidth > 992) {
		//hauteurEgale($(".same-height-navint"));
	};	
})