$(window).load(function () {
	function fixed_nav() {
		var wScrollTop = $(window).scrollTop();
		var navHeight = $('.header').height();
		if(wScrollTop > navHeight+200) {
			$('.header').addClass('fixed');
		} else {
			$('.header').removeClass('fixed');
		}
	}

	window.onscroll = function() {
		fixed_nav();
	};


	$(window).load(function() {
		fixed_nav();
	});

	$(".fancybox").fancybox({
		'transitionIn'	:	'elastic',
		'transitionOut'	:	'elastic',
		'speedIn'		:	600,
		'speedOut'		:	200,
		'overlayShow'	:	true,
		helpers: {
			title : {
				type : 'float'
			}
		}
	});
})